.container-application {
  &.login {
    height: 100vh;
    align-items: center !important;
  }
  .login-wrapper {
    margin: 0 auto;
    background-color: #162e4d;
    max-width: 400px;
    width: 400px;
    min-height: 450px;
    border-radius: 10px;
    form {
      margin: 0% 9%;
      .input-wrapper {
        input {
          padding: 10px 10px;
          width: 100%;
          max-width: 400px;
          border-radius: 10px;
          border: none;
        }
        .pwd-img {
          margin: -30px;
          cursor: pointer;
        }
      }
    }
  }
}
