.manage-staff-page {
  text-align: center;
  margin-top: 5%;
  background-color: #162e4d;
  margin: 8% 7%;
  min-height: 550px;
  border-radius: 10px;

  h5 {
    margin: 1.5rem 0rem;
  }
  p {
    color: white;
  }
}
