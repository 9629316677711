.queue-monitor-page {
  .kanban-board {
    min-height: 600px;
  }
}
.ReactModal__Overlay {
  background-color: unset !important;
  .logout-modal {
    h5 {
      color: #1b385d;
    }
    .btn-wrapper {
      .custom-btn-primary {
        background-color: #1b385d;
        border-color: #1b385d;
        color: #fff;
      }
      .custom-btn-danger {
        color: red;
      }
    }
  }
}
